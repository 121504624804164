import React from "react";
import './window.css';

interface IWindowProps {
  children?: any;
}

export default ({children}: IWindowProps) => {
  return (
    <div style={{
      backgroundColor: "white", 
      position: "absolute",
      left: 10,
      top: 50,
      width: (window.innerWidth > 800 ? window.innerWidth * 0.7 : window.innerWidth) - 10,
     }}>
      {children}
    </div>
  );
}

import React, { Component, CSSProperties } from 'react';
import fridgecow from "./fridgecow.png";
import dude from "./dude.png";
import "./physcow.css";

interface IPosition {
  x: number;
  y: number;
  r: number;
}

interface IPhysCowProps {
  style?: CSSProperties;
  cowStyle?: CSSProperties;

  start?: IPosition;
  initialVel?: IPosition;

  diameter?: number;
  friction?: number;
  bounciness?: number;
  gravity?: number;
  floor?: number;
  movable?: boolean;

  onStartMoving?: () => void;
  onEndMoving?: () => void;
  onMovingSlowly?: () => void;
  onMovingQuickly?: () => void;

  animateTo?: number;
  match?: string;
  displayDude?: boolean;
  dudeClick?: () => void;
}

export default class PhysCow extends Component<IPhysCowProps> {
  x = 0;
  y = 0;
  r = 0;
  velX = 0;
  velY = 0;
  velR = 0;

  width = 0;
  height = 0;

  animating = false;
  movingSlowly = false;

  animateTo = 0;
  match = "";

  static defaultProps = {
    start: {x: 0, y: 0, r: 0},
    initialVel: {x: 0, y: 0},
    friction: 0.95,
    bounciness: 0.8,
    gravity: 1,
    diameter: 100,
    movable: true,

    onStartMoving: () => {},
    onEndMoving: () => {},
  }

  animateCow = () => {
    if(!this.animating){
      this.animating = true;
      this.props.onStartMoving!()
    }

    // Apply gravity and friction
    this.velX *= this.props.friction!
    this.velR *= this.props.friction!
    this.velY -= this.props.gravity!

    // Change position
    this.x += this.velX;
    this.y -= this.velY;
    this.r += this.velX;

    // Check bounds
    if (this.x > this.width || this.x < -1) {
      this.x = Math.min(Math.max(this.x, 0), this.width)
      this.velX *= -1;
      this.velR *= -1;
    }
    if (this.y > this.height) {
      this.y = Math.min(this.y, this.height)
      this.velY *= -this.props.bounciness!;
    }

    // Render
    this.forceUpdate();

    if(Math.abs(this.velX) <= 0.1 && Math.abs(this.velY) <= 1){
      if (!this.movingSlowly) {
        this.movingSlowly = true;
        this.props.onMovingSlowly!();
      }
    }else{
      if (this.movingSlowly) {
        this.movingSlowly = false;
        this.props.onMovingQuickly!();
      }
    }

    if(Math.abs(this.velX) <= 0.0001 && Math.abs(this.velY) <= 0.5){
      this.animating = false;
      this.velX = 0;
      this.velY = 0
      this.props.onEndMoving!();
      // One last render
      this.forceUpdate();
      return;
    }

    // Next frame
    window.requestAnimationFrame(this.animateCow)
  }

  beginAnimation = (container: HTMLDivElement) => {
    this.x = this.props.start!.x
    this.y = this.props.start!.y
    this.r = this.props.start!.r
    this.velX = this.props.initialVel!.x 
    this.velY = this.props.initialVel!.y
    this.velR = this.props.initialVel!.r

    if(container){
      this.width = container.clientWidth - this.props.diameter!
      this.height = (this.props.floor || 1)*(container.clientHeight - this.props.diameter!)
    }else{
      return
    }

    window.addEventListener("resize", () => {
      this.width = container.clientWidth - this.props.diameter!
      this.height = (this.props.floor || 1)*(container.clientHeight - this.props.diameter!)
    });

    this.animateCow()
  }

  componentDidUpdate(){
    if(this.match !== this.props.match){
      this.match = this.props.match || "";
      this.animateTo = this.props.animateTo || 0;
      if(this.props.animateTo){

        // Calculate (approx) velocity
        const delta = this.animateTo - this.x;
        this.velX = delta/19.12; // geometric sum of friction over 60 frames

        if(!this.animating){
          this.animateCow()
        }
      }
    }
  }

  interactHandler(clientx: number, clienty: number){
      const diameter = this.props.diameter

      // Get direction being pushed
      const x = clientx - this.x - diameter!/2;
      const y = clienty - this.y - diameter!/2;
      const distance = Math.sqrt(x*x + y*y);
      
      const infraction = diameter!/2 - distance;
      if (infraction < 0) {
        return;
      }

      const angle = Math.atan(y/x);
      const distanceToMove = Math.cos(angle)*infraction*1.5 ;
      const directionToMove = x > 0 ? -1 : 1;
      
      const distanceToJump = Math.sin(angle)*infraction;
      if (y > 0){
        this.velY = distanceToJump;
      }

      this.velX = distanceToMove*directionToMove;
      if(!this.animating){
        this.animateCow()
      }
  }

  render() {
    const {start, initialVel, style, cowStyle, diameter, movable, animateTo, displayDude, dudeClick, ...props} = this.props
    const diameter_default = diameter || 200;

    return (
      <div 
        style={{overflow: "hidden", ...style}} {...props} ref={this.beginAnimation} 
        onDragStart={e => e.preventDefault()} >
        {
          displayDude && !this.animating ? <img 
            src={dude} 
            style={{
              position: "absolute",
              transformOrigin: "center", 
              top: this.y - (diameter_default/2)*0.65,
              left: this.x - (diameter_default/2)*0.65,
              WebkitUserSelect: "none",
              msUserSelect: "none",
              MozUserSelect: "none",
              animation: "fadeInAnimation ease 1s",
            }}
            height={(diameter || 200)*1.36}
            draggable={false}
            onClick={dudeClick}
          /> : null
        }
        <img 
          src={fridgecow} 
          style={{
            transformOrigin: "center", 
            transform: `translate(${this.x}px, ${this.y}px) rotate(${this.r}deg)`, 
            WebkitUserSelect: "none",
            msUserSelect: "none",
            MozUserSelect: "none",
            ...cowStyle,
          }}
          draggable={false}
          onMouseMove={movable ? e => this.interactHandler(e.clientX, e.clientY) : undefined}
          onTouchStart={movable ? e => this.interactHandler(e.touches[0].clientX, e.touches[0].clientY) : undefined}
          onTouchMove={movable ? e => this.interactHandler(e.touches[0].clientX, e.touches[0].clientY) : undefined}
          width={diameter}
          height={diameter} />
      </div>
    );
  }
}

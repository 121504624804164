import React from "react"
import { Route, NavLink } from "react-router-dom";

import "./NavBar.css";

interface ILinkInfo {
  path: string;
  name: string;
}

interface INavProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  links: ILinkInfo[];
}

export default ({links, ...props}: INavProps) => {
  return <nav {...props}>
    <ul className="navbar-list">
      <li key="home"><NavLink exact={true} to="/" className="navbar-link">⌂</NavLink></li>
      {links.map(
        ({path, name}) => <li key={path}><NavLink to={path} className="navbar-link">{name}</NavLink></li>
      )}
    </ul>
  </nav>;
}

import React, { Component } from "react";

interface IAsyncState {
  component: any
}

export default (importComponent: any) => {
  class AsyncComponent extends Component {
    state: IAsyncState = {
      component: null
    }

    constructor(props: any) {
      super(props);
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component: component
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}


import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";
import './App.css';

import PhysCow from "./components/physcow/PhysCow";
import NavBar from "./components/navbar/NavBar";
import Window from "./components/window/Window";

import martyna from "./martyna.png";

const About = asyncComponent(() => import("./pages/about/About"));
const Projects = asyncComponent(() => import("./pages/projects/Projects"));
const Map = asyncComponent(() => import("./pages/map/Map"));

const links = [
  {
    path: "/about",
    name: "about",
    component: () => <Window>
      <h1>Hi, I'm Tom!</h1>
      <About />
    </Window>,
  },
  {
    path: "/projects",
    name: "projects",
    component: () => <Window>
      <h1>Past and Current Projects</h1>
      <Projects />
    </Window>,
  },
  {
    path: "/map",
    name: "map",
    component: () => <Window>
      <h1>Map</h1>
      <Map />
    </Window>,
  },
  {
    path: "/blog",
    name: "blog",
    component: () => <Window><h1>Blog</h1><p>Nothing to see here yet!</p></Window>,
  },
]

class App extends Component {
  state = {
    playing: false
  }

  render() {
    return (
      <Router>
        <NavBar 
          links={links} 
          style={{
            position: "absolute", 
            opacity: this.state.playing ? 0.1 : 1, 
            transition: "1s opacity"
          }}
        />
        {
          links.map(({path, component}) => <Route path={path} component={component} />)
        }
        <Route path="/martyna" component={() => <Window><img src={martyna} /></Window>} />
        <Route path="/" render={({match, history}) => {
            if (!match.isExact && window.innerWidth < 800){
              return null
            }
            console.log(match)
            return <PhysCow 
              start={{x: 0, y: 0, r: 0}} 
              floor={0.5} 
              diameter={200} 
              initialVel={{x: 100, y: 0, r: 10}} 
              style={{width: "100vw", height: "100vh"}}
              onMovingQuickly={() => this.setState({playing: true})}
              onMovingSlowly={() => this.setState({playing: false})}
              animateTo={match.isExact ? undefined : document.body.clientWidth * 0.80}
              match={location.href}
              displayDude={location.href.endsWith("/about")}
              dudeClick={() => history.push("/martyna")}
            />
          }
        } />
      </Router>
    );
  }
}

export default App;
